const form = document.querySelector("[data-freeform]");
// Override the way field errors are displayed
form.addEventListener('freeform-render-field-errors', function(event) {
    console.log('HEY');
    // Prevent the default behaviour
    event.preventDefault();
  
    const errors = event.errors;
    for (const key in errors) {
      if (!errors.hasOwnProperty(key) || !key) {
        continue;
      }
  
      const messages = errors[key];
      const errorsList = document.createElement("ul");
      errorsList.classList.add("errors");
  
      for (let messageIndex = 0; messageIndex < messages.length; messageIndex++) {
        const message = messages[messageIndex];
        const listItem = document.createElement("li");
        listItem.appendChild(document.createTextNode(message));
        errorsList.appendChild(listItem);
      }
  
      const inputList = form.querySelectorAll("*[name=" + key + "], *[name='" + key + "[]']");
      for (let inputIndex = 0; inputIndex < inputList.length; inputIndex++) {
        const input = inputList[inputIndex];
        input.classList.add("is-error");
        input.closest('.main-column-class').appendChild(errorsList);
      }
    }
  });
  form.addEventListener('freeform-remove-field-messages', function (event) {
    // Prevent the default behaviour
    event.preventDefault();
    console.log(event.field);
    event.field.classList.remove('is-error');
    const errorBlock = event.field.closest('.main-column-class').querySelector('.errors');
    errorBlock.remove();
  });



  document.addEventListener("DOMContentLoaded", function() {
    const players = Array.from(document.querySelectorAll('.ArticleBody-video')).map((p) => new Plyr(p));
    // console.log(players);
    // new Plyr("#player");
    // new Plyr("#player2");
    
});